// i18next-extract-mark-ns-start bbva-shopify

import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { SectionActions, SectionHeader } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import shopifyBbva from "images/shopify-bbva.svg";
import React from "react";
import styled from "styled-components";
import { IndexImage } from "components/landings/IndexImage";
import { Bold } from "components/StickyBanner";
import { InternalPageLink, PciDssLink, PricingLink, ShopifyPaymentGatewayLink } from "components/links/Pages";
import { AnchorLink } from "components/AnchorLink";
import { BlogLink } from "components/links/Blog";

const IndexSection = styled.div`
  position: relative;
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 90px;
  max-width: 500px;
`;

const BbvaShopify: React.FC = () => {
  const {t} = useI18next();

  return (
    <Content>
      <SEO
        path="bbva-shopify"
        title="BBVA Shopify"
        description={t(
          'Connect your Shopify online store to BBVA clients via MONEI, the first ever payment gateway to integrate directly with Shopify!'
        )}
      />
      <IndexSection>
        <IndexContent>
          <SectionHeader underline tagName="h1">
            <Trans>BBVA Shopify</Trans>
          </SectionHeader>
          <Trans parent="p">
            <Bold>
              Reach more than half a million BBVA customers in Spain with MONEI as your payment
              gateway.
            </Bold>
          </Trans>
          <Trans parent="p">
            Quickly and easily{' '}
            <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/" external>
              integrate your Shopify store
            </AnchorLink>{' '}
            to accept a wide range of{' '}
            <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> including
            alternative and local payment methods like Google Pay, Apple Pay, Bizum, Multibanco,
            SEPA Direct Debit, and more.
          </Trans>
          <Trans parent="p">
            Sell more, <PricingLink>save money</PricingLink> on transaction fees, and leave payment
            security to us. MONEI is <PciDssLink>PCI DSS compliant</PciDssLink> and supports{' '}
            <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
              3D Secure
            </BlogLink>{' '}
            and{' '}
            <BlogLink slug="psd2-and-strong-customer-authentication-sca-explained">
              SCA verification
            </BlogLink>
            .
          </Trans>
          <Trans>
            <Bold>
              Choose the best{' '}
              <ShopifyPaymentGatewayLink>Shopify payment gateway</ShopifyPaymentGatewayLink> — get
              started with MONEI today.
            </Bold>
          </Trans>
          <SectionActions align="left">
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
            <ContactSalesButton />
          </SectionActions>
        </IndexContent>
        <IndexImage
          left="50%"
          top="65px"
          width="700px"
          src={shopifyBbva}
          title="BBVA Shopify"
          alt="BBVA Shopify"
          className="hide-on-mobile"
        />
      </IndexSection>
    </Content>
  );
};

export default BbvaShopify;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "bbva-shopify"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
